import $ from 'jquery';

window.PhysicalInventory ={}

PhysicalInventory.inventoryItems = []
PhysicalInventory.searchedItems = ""

PhysicalInventory.fullSelect = (() => {
   $('.hidden-card').removeClass('d-none');
   $('.items-card').addClass('d-none');
 });

PhysicalInventory.partialSelect = (function(){
   $('.items-card').removeClass('d-none');
   $('.hidden-card').addClass('d-none');
});

PhysicalInventory.addInventoryItem = ((item) => {

  var index = PhysicalInventory.inventoryItems.findIndex(x => x.item_id === item.id);
  var selected;

  if (index < 0){
    var newItem = { 
      item_id: item.id, item_name: item.name,
      item_sku: item.sku ? item.sku : '', count: item.quantity
    }
    PhysicalInventory.inventoryItems.push(newItem)
    selected = newItem
  } else if (PhysicalInventory.inventoryItems[index]._destroy == true){
    selected = PhysicalInventory.inventoryItems[index]
    PhysicalInventory.inventoryItems[index]._destroy = false
  }
  else{
    return
  }

  document.getElementById("physical-inventory-item-list").value =  JSON.stringify(PhysicalInventory.inventoryItems);

  var selectedTemp = `
    <tr id="inventory-item-${selected.item_id}">
      <td scope="col-8">
        <h5 class="font-weight-bold"> ${selected.item_name} </h5>
        ${selected.item_sku}
      </td>
      <td class="text-right"> 
        <span class="text-right mr-4"> ${selected.count} </span>
        <a href="javascript:void(0)" onclick="PhysicalInventory.removeInventoryItem( '${selected.id}' , '${selected.item_id}' )">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </a>
      </td>
    </tr>
  `
  $("#items-list").append(selectedTemp)
});


PhysicalInventory.removeInventoryItem = ((id, itemId) => {
  var index = PhysicalInventory.inventoryItems.findIndex(x => x.item_id === itemId);
  PhysicalInventory.inventoryItems.splice(index, 1)
  document.getElementById("physical-inventory-item-list").value =  JSON.stringify(PhysicalInventory.inventoryItems);
  $(`#inventory-item-${itemId}`).remove()
});


PhysicalInventory.searchItem = ( function(that,searchKey) {
  if (that.value != '') {
      var search = that.value.toLowerCase();
      $.ajax({
      url: '/items/search',
      type: "GET",
      contentType: 'application/json',
      dataType: 'json',
      data: {
         filter: {[searchKey]: that.value}
       },
      }).done(function(data) {
          PhysicalInventory.searchedItems = data

          let list = "";
          data.map(listData => {
              list += `<li onclick="PhysicalInventory.itemSelected(this,'${listData.id}')" >
              <a href="javascript:void(0)">
                <h5 class="font-weight-bold">
                  ${listData.name}
                </h5>
                ${listData.sku || ''}
              </a>
              </li>`
          })
          that.parentElement.nextElementSibling.innerHTML = list;
      })
      .fail( err =>{
          console.log(err);
      });
  }else{
      that.parentElement.nextElementSibling.innerHTML = '';
  }
});

PhysicalInventory.searchCategory = ( function(that) {
  if (that.value != '') {
      var searchKey = that.value;
      $.ajax({
      url: '/categories/search',
      type: "GET",
      contentType: 'application/json',
      dataType: 'json',
      data: {
        search_value: searchKey
       },
      }).done(function(data) {
          PhysicalInventory.searchedItems = data
          let list = "";
          data.map(listData => {
              list += `<li onclick="PhysicalInventory.categorySelected(this,'${listData.id}')" >
              <a href="javascript:void(0)">
                <h5 class="font-weight-bold">
                  ${listData.name}
                </h5>
              </a>
              </li>`
          })
          that.parentElement.nextElementSibling.innerHTML = list;
      })
      .fail( err =>{
          console.log(err);
      });
  }else{
    that.parentElement.nextElementSibling.innerHTML = '';
  }
});

PhysicalInventory.categorySelected = ( function(that, categoryId){
  $.ajax({
    url: '/items/search',
    type: "GET",
    contentType: 'application/json',
    dataType: 'json',
    data: {
      filter: {category_id: categoryId}
    },
  }).done(function(data) {
    PhysicalInventory.searchedItems = data
    data.forEach(function(item){
      PhysicalInventory.itemSelected(that, item.id)
    })
  })
});

PhysicalInventory.itemSelected = ( function(that, itemId){
  var index = PhysicalInventory.searchedItems.findIndex(x => x.id === itemId);
  PhysicalInventory.addInventoryItem(PhysicalInventory.searchedItems[index]);
  if (that.parentElement) {
    that.parentElement.innerHTML = '';
    that.value = ''
  }

});
