document.addEventListener('turbolinks:load', () => {
  if ($("#discount_discount_type").val() == "amount"){
    $('#discount_discount_value').addClass('maskmoney');
    Customjs.initializeMaskMoney();
  } else {
    $("#discount_percentage").inputmask('percentage',{
      alias: "percentage",
      digits: "2",
      rightAlign: false,
      integerDigits: 5,
      digitsOptional: true,
      allowPlus: true,
      allowMinus: false,
      placeholder: "0",
      min: 0,
      max: 100,
      numericInput: true,
      removeMaskOnSubmit: true
    });
  }

  $("#discount_discount_type").on('change', function(){
    if ($("#discount_discount_type").val() == "amount"){
      $('#discount_percentage').attr('name', 'discount[discount_value]');
      $('#discount_percentage').attr('id', 'discount_discount_value');
      $("#discount_discount_value").val('');
      $("#discount_discount_value").inputmask('remove');
      $('#discount_discount_value').addClass('maskmoney');
      Customjs.initializeMaskMoney();
    } else {
      $('#discount_discount_value').attr('name', 'discount[percentage]');
      $('#discount_discount_value').attr('id', 'discount_percentage');
      $("#discount_percentage").val('');
      $('#discount_percentage').removeClass('maskmoney');
      $("#discount_percentage").maskMoney('destroy');
      $("#discount_percentage").inputmask('percentage',{
        alias: "percentage",
        digits: "2",
        rightAlign: false,
        integerDigits: 5,
        digitsOptional: true,
        allowPlus: true,
        allowMinus: false,
        placeholder: "0",
        min: 0,
        max: 100,
        numericInput: true,
        removeMaskOnSubmit: true
      });
    }
  })
});