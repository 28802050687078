$.valHooks.textarea = {
get: function( elem ) {
return elem.value.replace( /\r?\n/g, "<br />" );
}                     };

document.addEventListener('turbolinks:load', () => {
  $(function() {
    applyFieldChange('#merchant_location_business_name', '#merchant_location_business_name_value', I18n.registration.business_name);
    applyFieldChange('#merchant_location_receipt_header', '#merchant_location_receipt_header_value', I18n.receipt_header);
    applyFieldChange('#merchant_location_receipt_footer', '#merchant_location_receipt_footer_value', I18n.receipt_footer);
    applyFieldChange('#merchant_location_name', '#merchant_location_name_value', I18n.merchant_location.name);
    applyFieldChange('#merchant_location_street_name', '#merchant_location_street_name_value', I18n.address_street_name);
    applyFieldChange('#merchant_location_district_name', '#merchant_location_district_name_value', I18n.address_district_name);
    applyFieldChange('#merchant_location_city', '#merchant_location_city_value', I18n.address_city);

  });
});


function applyFieldChange(inputFieldClass, viewFieldClass, placeHolder="") {
  var field = $(inputFieldClass);
  
  if (field.length) {
    var inputFieldValue = $(inputFieldClass).val();
    if (inputFieldValue.length === 0) {
      $(viewFieldClass).text(placeHolder);
    } else {
      $(viewFieldClass).html(inputFieldValue);
    }

    $(inputFieldClass).on("input", function() {
      var inputFieldValue = $(inputFieldClass).val()
      if (inputFieldValue.length === 0) {
        $(viewFieldClass).text(placeHolder);
      } else {
        $(viewFieldClass).html(inputFieldValue);
      }
    });
  }
}