import $ from 'jquery';

window.PurchaseOrder ={}

PurchaseOrder.orderItems = {}
PurchaseOrder.searchedItems = ""
PurchaseOrder.formType = ""

PurchaseOrder.addOrderItem = (function(item){
  var index = PurchaseOrder.orderItems[item.id]
  var selected;
  if (index == undefined){
    var newItem = { 
      item_id: item.id, item_name: item.name,
      item_sku: item.sku, item_count: item.quantity,
      incoming: 0, ordered_quantity: 0, cost: 0, id: null
    }

    PurchaseOrder.orderItems[item.id] = newItem
    selected = newItem
    var idString = ""
    if(PurchaseOrder.formType == 'edit' && selected.id != null){
      idString = `<input type='hidden' name="purchase_order_edit_form[order_items][${selected.item_id}][id]" value=${selected.id} >`
    }

    var selectedTemp = `
      <tr id="order-item-${selected.item_id}">
        <td scope="col-2">
          <h5 class="font-weight-bold"> ${selected.item_name} </h5>
            ${idString}
            <input type='hidden' name="purchase_order_${PurchaseOrder.formType}_form[order_items][${selected.item_id}][item_id]" value=${selected.item_id} >
            <input type='hidden' name="purchase_order_${PurchaseOrder.formType}_form[order_items][${selected.item_id}][item_name]" value=${selected.item_name} >
            <input type='hidden' name="purchase_order_${PurchaseOrder.formType}_form[order_items][${selected.item_id}][item_sku]" value=${selected.item_sku} >
        </td>

        <td scope="col-2">
          <div class="form-group" >
            <input type="number" step="any" name="purchase_order_${PurchaseOrder.formType}_form[order_items][${selected.item_id}][ordered_quantity]" value=${selected.ordered_quantity} class="form-control item-quantity">
          </div>
        </td>

        <td scope="col-2">
          <div class="form-group" >
            <input type="text" name="purchase_order_${PurchaseOrder.formType}_form[order_items][${selected.item_id}][item_cost]" value=${selected.cost} class="maskmoney form-control item-cost">
          </div>
        </td>

        <td class="text-right">
          <a href="javascript:void(0)" onclick="PurchaseOrder.removeOrderItem( '${selected.id}' , '${selected.item_id}' )">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </td>
      </tr>
    `
    $("#order-items-list").append(selectedTemp)
  }
  else if (PurchaseOrder.orderItems[item.id]._destroy == true){
    selected = PurchaseOrder.orderItems[item.id]
    PurchaseOrder.orderItems[item.id]._destroy = false
    $(`#order-item-${itemId}`).removeClass('d-none');
  }
  else{
    return
  }
  
});

// <span class="text-right mr-4"> ${selected.item_count} </span>
PurchaseOrder.removeOrderItem = ((id, itemId) => {
  var index = PurchaseOrder.orderItems[itemId];
  if( id!= "null" && id != null ){
    PurchaseOrder.orderItems[itemId]._destroy = true
    $(`#purchase_order_edit_form_order_items_${itemId}__destroy`).val(true)
    $(`#order-item-${itemId}`).addClass('d-none')
  }else{
    delete PurchaseOrder.orderItems[itemId]
    $(`#order-item-${itemId}`).remove()
  }  
});

PurchaseOrder.getTotalvalues = () =>{
  var additional_costs = $(".additional_costs").val() ? parseFloat($(".additional_costs").val()) : 0;
  var all_total_cost = 0;
  var all_total_vat = 0;
  $(".item-cost").each((index,item) => {
    var value = item.value;

    if (value.length) {
      all_total_cost += parseFloat(value);
    }
  });

  var allCosts = all_total_cost + additional_costs;
  $(".all-total-cost").text(allCosts.toFixed(2));
  $(".all-total-vat").text($(".total_vat").val());

}

$(document).on("keyup change",".item-cost, .total_vat", function(){
 PurchaseOrder.getTotalvalues();
});

$(document).on("keyup change",".additional_costs", function(){
  PurchaseOrder.getTotalvalues();
 });

PurchaseOrder.removeOrderItem = ((id, itemId) => {
  var index = PurchaseOrder.orderItems[itemId];
  if( id!= "null" && id != null ){
    PurchaseOrder.orderItems[itemId]._destroy = true
    $(`#purchase_order_edit_form_order_items_${itemId}__destroy`).val(true)
    $(`#order-item-${itemId}`).addClass('d-none')
  }else{
    delete PurchaseOrder.orderItems[itemId]
    $(`#order-item-${itemId}`).remove()
  }  
});

PurchaseOrder.searchItem = ( function(that,searchKey) {
  if (that.value != '') {
      var search = that.value.toLowerCase();
      $.ajax({
      url: '/items/search',
      type: "GET",
      contentType: 'application/json',
      dataType: 'json',
      data: {
         filter: {[searchKey]: that.value}
       },
      }).done(function(data) {
          PurchaseOrder.searchedItems = data
          let list = "";
          data.map(listData => {
              list += `<li onclick="PurchaseOrder.itemSelected(this,'${listData.id}')" >
              <a href="javascript:void(0)">
                <h5 class="font-weight-bold">
                  ${listData.name}
                </h5>
                ${listData.sku || ''}
              </a>
              </li>`
          })
          that.parentElement.nextElementSibling.innerHTML = list;
      })
      .fail( err =>{
          console.log(err);
      });
  } else {
      that.parentElement.nextElementSibling.innerHTML = '';
  }
});

PurchaseOrder.itemSelected = ( function(that,itemId){
  var index = PurchaseOrder.searchedItems.findIndex(x => x.id === itemId);
  PurchaseOrder.addOrderItem(PurchaseOrder.searchedItems[index]);
  that.parentElement.innerHTML = '';
  that.value = ''
  setTimeout(function() {
    Customjs.initializeMaskMoney(); 
  }, 500);
  
});

