// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
import './modules/common/jquery-maskmoney.js';
import './modules/common/jquery.inputmask.min.js';
global.$ = $;
import 'bootstrap';
import "chartkick/chart.js"

import appInit from './modules/app.init.js';
import initDropzone from './modules/common/dropzone';
import './modules/common/jquery.are-you-sure.js';
import './modules/common/ays-beforeunload-shim.js';
// import addOption from "customjs"
// import I18n from "i18n-js";
// global.I18n = I18n;
require("@fortawesome/fontawesome-free/js/all")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('datatables.net-bs')
require('datatables.net-bs4/js/dataTables.bootstrap4.js')
require('datatables.net-bs4/css/dataTables.bootstrap4.css')
require('datatables.net-buttons')
require('datatables.net-buttons-bs')
require('datatables.net-responsive')
require('datatables.net-responsive-bs')
require('datatables.net-responsive-bs/css/responsive.bootstrap.css')
require('datatables.net-keytable');
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-keytable-bs/css/keyTable.bootstrap.css')
var moment = require('moment');
require('select2')
require('daterangepicker')
require("physical_inventory")
require("customjs")
require("purchase_order")
require("packs/settings")
require("packs/discounts")
require("packs/users")
require("packs/loyalty_program")
require("packs/employees_form")
require("packs/roles")
require("packs/dashboard")
require("packs/merchant_locations")
require("selectize")
require("packs/tags")
require("packs/subscriptions")

// Kicks off the process of finding <i> tags and replacing with <svg>
  function rangePickerApplyAction(parent) {
    $('.date_range_picker').on('apply.daterangepicker', function(ev, picker) {
      if (picker.startDate && picker.endDate) {
        var start = picker.startDate.format('DD-MM-YYYY');
        var end   = picker.endDate.format('DD-MM-YYYY');
        $.fn.dataTable.ext.search.push(
          function(settings, data, dataIndex) {
            var min = start;
            var max = end;
            var startDate = new Date(start);
            
            if (min == null && max == null) {
              return true;
            }
            if (min == null && startDate <= max) {
              return true;
            }
            if (max == null && startDate >= min) {
              return true;
            }
            if (startDate <= max && startDate >= min) {
              return true;
            }
            return false;
          }
        );

        var location_filter = $("#location-id").val();
        var brand_filter = $("#brand-id").val();
        var params = `?date_range_min=${start}&date_range_max=${end}`;

        if (location_filter !== undefined && location_filter != ""){
          params += `&location_filter=${location_filter}`
        }

        if (brand_filter !== undefined && brand_filter != ""){
          params += `&brand_filter=${brand_filter}`
        }

        parent.ajax.url(params).load();
        const exportCSV = $("#ExportReporttoCSV");

        if (exportCSV.length) {
          var href = $("#ExportReporttoCSV").attr("href").split("?")[0]
          $("#ExportReporttoCSV").attr("href", href + params)
        }
      }
    });
  }

  function initializeDateRangePickers(live=false) {
    var date_range_picker = $('.date_range_picker');
    var minDate = '';
    // this can be moved later, 
    date_range_picker.each(function(){
      var calendarMin = $(this).data('calendar-date')
      if (calendarMin === 'min') {
        minDate = moment().subtract(60, 'days') 
      }
    })

    var start = moment().startOf('month');
    var end = moment().endOf('month');

    if (live) {
      var start = moment().startOf('day');
      var end = moment().endOf('day');
    }

    var direction = I18n.locale === 'en' ? 'right' : 'left'
    date_range_picker.daterangepicker({
      opens: direction,
      drops: 'down',
      showDropdowns: false,
      showWeekNumbers: false,
      timePicker: false,
      startDate: start,
      endDate: end,
      buttonClasses: ['btn', 'btn-sm'],
      applyClass: 'btn-primary btn-daterange-submit',
      cancelClass: 'btn-default',
      locale: {
         format: 'DD-MM-YYYY',
         "separator": " - ",
         "applyLabel": I18n.Apply,
         "cancelLabel": I18n.Cancel,
         "fromLabel": "From",
         "toLabel": "To",
         "customRangeLabel": I18n["Custom date"],
         "weekLabel": "W",
         "daysOfWeek": [
             I18n["Sunday"],
             I18n["Monday"],
             I18n["Tuesday"],
             I18n["Wednesday"],
             I18n["Thursday"],
             I18n["Friday"],
             I18n["Saturday"]
         ],
         "monthNames": [
             I18n["January"],
             I18n["February"],
             I18n["March"],
             I18n["April"],
             I18n["May"],
             I18n["June"],
             I18n["July"],
             I18n["August"],
             I18n["September"],
             I18n["October"],
             I18n["November"],
             I18n["December"]
         ],
         "firstDay": 1
      },
      ranges: {
        [I18n['Last 1 Day']]: [moment().subtract(1, 'days'), moment()],
        [I18n['Last 7 Days']]: [moment().subtract(7, 'days'), moment()],
        [I18n['Last 15 Days']]: [moment().subtract(15, 'days'), moment()],
        [I18n['Last 1 Month']]: [moment().subtract(1, 'months'), moment()],
        [I18n['Last 3 Months']]: [moment().subtract(3, 'months'), moment()],
      }
    });
  };

document.addEventListener('turbolinks:load', function() {
  Customjs.initializeMaskMoney();
  // show onboarding
  var ele = document.getElementsByClassName("show_onboarding")
  if (ele.length) {
    $('#afterSignUp').modal('show');
  }

  var locale = I18n.locale
  var englishOption = {
                        "sEmptyTable":     "No data available in table",
                        "sInfo":           "Showing _START_ to _END_ of _TOTAL_ entries",
                        "sInfoEmpty":      "Showing 0 to 0 of 0 entries",
                        "sInfoFiltered":   "(filtered from _MAX_ total entries)",
                        "sInfoPostFix":    "",
                        "sInfoThousands":  ",",
                        "sLengthMenu":     "Show _MENU_ entries",
                        "sLoadingRecords": "Loading...",
                        "sProcessing":     "Processing...",
                        "sSearch":         "Search:",
                        "sZeroRecords":    "No matching records found",
                        "oPaginate": {
                          "sFirst":    "First",
                          "sLast":     "Last",
                          "sNext":     "Next",
                          "sPrevious": "Previous"
                        },
                        "oAria": {
                          "sSortAscending":  ": activate to sort column ascending",
                          "sSortDescending": ": activate to sort column descending"
                        }
                      }
  var arabicOption = {
                        "sEmptyTable":     "ليست هناك بيانات متاحة في الجدول",
                        "sLoadingRecords": "جارٍ التحميل...",
                        "sProcessing":   "جارٍ التحميل...",
                        "sLengthMenu":   "أظهر _MENU_ مدخلات",
                        "sZeroRecords":  "لم يعثر على أية سجلات",
                        "sInfo":         "إظهار _START_ إلى _END_ من أصل _TOTAL_ مدخل",
                        "sInfoEmpty":    "يعرض 0 إلى 0 من أصل 0 سجل",
                        "sInfoFiltered": "(منتقاة من مجموع _MAX_ مُدخل)",
                        "sInfoPostFix":  "",
                        "sSearch":       "ابحث:",
                        "sUrl":          "",
                        "oPaginate": {
                          "sFirst":    "الأول",
                          "sPrevious": "السابق",
                          "sNext":     "التالي",
                          "sLast":     "الأخير"
                        },
                        "oAria": {
                          "sSortAscending":  ": تفعيل لترتيب العمود تصاعدياً",
                          "sSortDescending": ": تفعيل لترتيب العمود تنازلياً"
                        }
                      }

  appInit();

  $(".item-datatable").each(function(){
    var itemDatatable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.category_filter = $('#category-id').val();
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#category-id').val(data.category_filter);
        $('#location-id').val(data.location_filter);
        data.brand_filter = $('#brand-id').val();
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.category_filter = $('#category-id').val();
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      columns: [
        {
          data: null,
          render: function ( data, type, row ) {
            return `<a href="/items/${data.id}/edit" > ${data.name}</a>`
          }
        },
        {data: "category", orderable: false},
        {data: "quantity", orderable: false},
        {data: "price", orderable: false },
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
        itemDatatable.search($(this).val()).draw();
    })
  });

  $(".customer-datatable").each(function(){
    var customerDatatable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      ajax: $(this).data('url'),
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      columns: [
        {
          data: null,
          render: function ( data, type, row ) {
            return `<a href="/customers/${data.id}" > ${data.name}</a>`
          }
        },
        {data: "email"},
        {data: "phone_number", orderable: false},
        {data: "subscription_number", orderable: false},

      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
        customerDatatable.search($(this).val()).draw();
    })
  });

  $(".inventory-waste-datatable").each(function(){
    var inventoryWasteDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      bLengthChange: false,
      stateSave: true,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
        sSearch: '<em class="fas fa-search mr-3"></em>',
        sLengthMenu: '_MENU_ records per page',
        zeroRecords: 'Nothing found - sorry',
        oPaginate: {
          sNext: '<em class="fa fa-caret-right"></em>',
          sPrevious: '<em class="fa fa-caret-left"></em>'
        }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        data.brand_filter = $('#brand-id').val();
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      columns: [
        { data: 'product_name', orderable: false },
        { data: 'product_barcode', orderable: false },
        { data: 'product_quantity', orderable: false },
        { data: 'product_price', orderable: false },
        { data: 'product_total_cost', orderable: false },
        { data: 'employee_name', orderable: false },
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      inventoryWasteDataTable.search($(this).val()).draw();
    })
  });

  $(".employee-datatable").each(function(){
    var employeeDatatable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      bLengthChange: false,
      pageLength: 10,
      paging: true, // Table pagination
      ordering: true, // Column ordering
      info: true, // Bottom left status text
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        }
      },
      columns: [
        {
          data: null,
          render: function ( data, type, row ) {
            return `<a href="/employees/${data.id}/edit" > ${data.name}</a>`
          }
        },
        {data: "user_name"},
        {data: "can_login", orderable: false},
        {data: "role", orderable: false},
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
        employeeDatatable.search($(this).val()).draw();
    })
  });

  $(".physical-inventory-datatable").each(function(){
    var physicalInventoryDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      bLengthChange: false,
      stateSave: true,
      pageLength: 10,
      paging: true, // Table pagination
      ordering: true, // Column ordering
      info: true, // Bottom left status text
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search m-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        }
      },
      columns: [
        {
          data: null,
          render: function ( data, type, row ) {
            return `<a href="/physical_inventories/${data.id}" > ${data.name}</a>`
          }
        },
        {data: "count_id", orderable: false},
        {data: "status", orderable: true},
        {data: "created_at", orderable: true},
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      physicalInventoryDataTable.search($(this).val()).draw();
    })
  });

  $(".category-datatable").each(function(){
    var categoryDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      ajax: $(this).data('url'),
      columns: [
        {
          data: null,
          render: function ( data, type, row ) {
            return `<a href="/categories/${data.id}/edit" > ${data.name}</a>`
          }
        },
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      categoryDataTable.search($(this).val()).draw();
    })
  });


  $(".brand-datatable").each(function(){
    var brandDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      ajax: $(this).data('url'),
      columns: [
        {
          data: null,
          render: function ( data, type, row ) {
            return `<a href="/brands/${data.id}/edit" > ${data.name}</a>`
          }
        },
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      brandDataTable.search($(this).val()).draw();
    })
  });

  $(".invoice-datatable").each(function(){
    var discountDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      ajax:  {
        url: $(this).data('url')
      },
      columns: [
        {data: "billing_period", orderable: false},
        {data: "issue_date", orderable: false},
        {data: "subtotal_amount", orderable: false},
        {data: "tax_amount", orderable: false},
        {data: "net_total_amount", orderable: false},
        {
          data: null,
          orderable: false,
          render: function ( data, type, row ) {
            return `<a class="btn btn-info" target="_blank" href="/invoices/${data.id}.pdf">${I18n["Download PDF"]}</a>`
          }
        },
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      discountDataTable.search($(this).val()).draw();
    })
  });


  $(".discount-datatable").each(function(){
    var discountDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      columns: [
        {
          data: null,
          render: function ( data, type, row ) {
            return `<a href="/discounts/${data.id}/edit" > ${data.name}</a>`
          }
        },
        {data: "discount_type", orderable: false},
        {data: "value", orderable: false},
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      discountDataTable.search($(this).val()).draw();
    })
  });

  $(".receipt-datatable").each(function(){
    var receiptDatatable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      bLengthChange: false,
      pageLength: 10,
      ajax: $(this).data('url'),
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      columns: [
        {
          data: null,
          render: function ( data, type, row ) {
            return `<a href="/receipts/${data.id}" > ${data.receipt_number}</a>`
          }
        },
        {data: "amount"},
        {data: "date"}
      ],
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      receiptDatatable.search($(this).val()).draw();
    })
  });

  $(".device-datatable").each(function(){
    var table =  $(this).DataTable({
      processing: true,
      serverSide: true,
      searching: false,
      stateSave: true,
      ordering: false,
      bLengthChange: false,
      pageLength: 10,
      ajax: $(this).data('url'),
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      columns: [
        {
          data: null,
          orderable: false,
          className: "center",
          render: function ( data, type, row ) {
            return `<a href="/devices/${data.id}" >${data.name}</a>`
          }
        },
        {data: "device_type"},
        {data: "location_name"},
      ]
    });
  });

  $(".payment_method-datatable").each(function(){
    var table =  $(this).DataTable({
      processing: true,
      serverSide: true,
      searching: false,
      stateSave: true,
      ordering: false,
      bLengthChange: false,
      pageLength: 10,
      ajax: $(this).data('url'),
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      columns: [
        {
          data: null,
          orderable: false,
          className: "center",
          render: function ( data, type, row ) {
            return `<a href="/payment_methods/${data.id}/edit" >${data.name}</a>`
          }
        },
        {data: "payment_type"},
        {data: "active"}
      ]
    });
  });

  $(".modifier-datatable").each(function(){
    var modifierDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();

      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      columns: [
        {
          data: null,
          render: function ( data, type, row ) {
            return `<a href="/modifiers/${data.id}/edit" > ${data.name}</a>`
          }
        },
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      modifierDataTable.search($(this).val()).draw();
    })
  });

  $(".purchase-order-datatable").each(function(){
    var purchaseOrderDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      bLengthChange: false,
      pageLength: 10,
      paging: true, // Table pagination
      ordering: true, // Column ordering
      info: true, // Bottom left status text
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      columns: [
        {
          data: null,
          orderable: false,
          render: function ( data, type, row ) {
            return `<a href="/purchase_orders/${data.id}" > ${data.count_id}</a>`
          }
        },
        {data: "status", orderable: true},
        {data: "purchase_date", orderable: true},
        {data: "ordered", orderable: false},
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      purchaseOrderDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(purchaseOrderDataTable);
  });


  $(".sales-report-datatable").each(function(){
    var salesReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      ordering: false, // Column ordering
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.group_by_filter = $('#group-by-id').val();
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#group-by-id').val(data.group_by_filter);
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.group_by_filter = $('#group-by-id').val();
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {data: "gross_sales", orderable: false},
        {data: "net_sales", orderable: false},
        {data: "refund_amount", orderable: false},
        {data: "discounts", orderable: false},
        {data: "cost", orderable: false},
        {data: "tax", orderable: false},
        {data: "gross_profit", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      salesReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(salesReportDataTable);
  });


  $(".payment-type-report-datatable").each(function(){
    var paymentTypeReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
        buttons : [ {
            extend : 'csvHtml5',
            exportOptions : {
              modifier : {
                  order : 'applied',
                  page : 'all',
                  search : 'applied'
              }
            }
          } 
        ],
      columns: [
        {data: "payment_name", orderable: true},
        {data: "payment_amount", orderable: false},
        {data: "transactions", orderable: false},
        {data: "refund_amount", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      paymentTypeReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(paymentTypeReportDataTable);
  });


  $(".item-sales-report-datatable").each(function(){
    var itemSalesReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {data: "item", orderable: true},
        {data: "sold_count", orderable: false},
        {data: "net_sales", orderable: false},
        {data: "cost", orderable: false},
        {data: "gross_profit", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      itemSalesReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(itemSalesReportDataTable);
  });

  $(".category-sales-report-datatable").each(function(){
    var categorySalesReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {data: "category", orderable: true},
        {data: "sold_count", orderable: false},
        {data: "net_sales", orderable: false},
        {data: "cost", orderable: false},
        {data: "gross_profit", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      categorySalesReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(categorySalesReportDataTable);
  });

  $(".order-type-sales-report-datatable").each(function(){
    var orderTypeSalesReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {data: "order_type", orderable: true},
        {data: "payment_amount", orderable: false},
        {data: "transactions", orderable: false},
        {data: "refund_amount", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      orderTypeSalesReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(orderTypeSalesReportDataTable);
  });

  $(".employee-sales-report-datatable").each(function(){
    var employeeSalesReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      ordering: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {data: "employee_name", orderable: false},
        {data: "net_sales", orderable: false},
        {data: "gross_sales", orderable: false},
        {data: "refund_amount", orderable: false},
        {data: "discounts", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      employeeSalesReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(employeeSalesReportDataTable);
  });

  $(".discount-report-datatable").each(function(){
    var discountReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#location-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {data: "name", orderable: true},
        {data: "amount", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      discountReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(discountReportDataTable);
  });

  $(".purchases-report-datatable").each(function(){
    var purchasesReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);

      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {data: "date", orderable: true},
        {data: "item", orderable: false},
        {data: "quantity", orderable: false},
        {data: "total_cost", orderable: false},
        {data: "total_tax", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      purchasesReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(purchasesReportDataTable);
  });

  $(".taxes-report-datatable").each(function(){
    var taxesReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      ordering: false,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {data: "tax_included_amount", orderable: false},
        {data: "tax_added_amount", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      taxesReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(taxesReportDataTable);
  });


  $(".delivery-report-datatable").each(function(){
    var deliveryReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      ordering: false,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {data: "receipt_number", orderable: false},
        {data: "subscription_id", orderable: false},
        {data: "employee", orderable: false},
        {data: "date", orderable: false},
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      deliveryReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(deliveryReportDataTable);
  });

  $(".receipts-report-datatable").each(function(){
    var receiptsReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {
          data: null,
          orderable: false,
          className: "center",
          render: function ( data, type, row ) {
            return `<a href="/receipts/${data.id}" >${data.receipt_number}</a>`
          }
        },
        {data: "customer", orderable: false},
        {data: "employee", orderable: false},
        {data: "net_sales", orderable: false},
        {data: "payment_type", orderable: false},
        {data: "date", orderable: true},
      ],
      "dom": "lrtip",
      order: [[5, 'desc']]
    });

    $('#tableSearchBox').keyup(function() {
      receiptsReportDataTable.search($(this).val()).draw();
    });

    initializeDateRangePickers();
    rangePickerApplyAction(receiptsReportDataTable);

  });

  $(".timesheets-report-datatable").each(function(){
    var timesheetsReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {data: "employee", orderable: false},
        {data: "started_at", orderable: false},
        {data: "ended_at", orderable: false},
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      timesheetsReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(timesheetsReportDataTable);
  });

  $(".dashboard-report-datatable-restaurant").each(function(){
    var dashboardReportDataTableRestaurant = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: false,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      paging: false, // Table pagination
      ordering: false, // Column ordering
      info: false, // Bottom left status text
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      drawCallback: function ( row, data, index ) {
         var labels = [];
        $(".cardTable thead th").each(function () {
          labels.push($(this).text());
        });
        $(".cardTable tbody tr").each(function () {
          $(this)
            .find("td")
            .each(function (column) {
              $("<p class='card-title'>" + labels[column] + "</p>").prependTo(
                $(this)
              );
            });
        });
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
        dataSrc: function ( json ) {
          const chart = Chartkick.charts["chart-1"];
          const topPaymentChart = Chartkick.charts["top-payment-chart"];
          const topProducts = Chartkick.charts["top-products-chart"];

          if (chart) {
            chart.updateData(json.data[0]['hourly_orders'])
          }

          if (topPaymentChart) {
            topPaymentChart.updateData(json.data[0]['top_payments'])
          }

          if (topProducts) {
            topProducts.updateData(json.data[0]['top_products'])
          }

          return json.data;
        }
      },
      columns: [
        {data: "order_count", orderable: false},
        {data: "gross_sales", orderable: false},
        {data: "net_sales", orderable: false},
        {data: "tax_amount", orderable: false},
        {data: "total_discount_amount", orderable: false},
        {data: "refund_amount", orderable: false},
        {data: "pickup_orders", orderable: false},
        {data: "delivery_orders", orderable: false},
        {data: "dine_in_orders", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      dashboardReportDataTableRestaurant.search($(this).val()).draw();
    })

    initializeDateRangePickers(true);
    rangePickerApplyAction(dashboardReportDataTableRestaurant);
  });

  $(".dashboard-report-datatable-retail").each(function(){
    var dashboardReportDataTableRetail = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: false,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      paging: false, // Table pagination
      ordering: false, // Column ordering
      info: false, // Bottom left status text
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      drawCallback: function ( row, data, index ) {
         var labels = [];
        $(".cardTable thead th").each(function () {
          labels.push($(this).text());
        });
        $(".cardTable tbody tr").each(function () {
          $(this)
            .find("td")
            .each(function (column) {
              $("<p class='colHeader'>" + labels[column] + "</p>").prependTo(
                $(this)
              );
            });
        });
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
        dataSrc: function ( json ) {
          const chart = Chartkick.charts["chart-1"];
          const topPaymentChart = Chartkick.charts["top-payment-chart"];
          const topProducts = Chartkick.charts["top-products-chart"];

          if (chart) {
            chart.updateData(json.data[0]['hourly_orders'])
          }

          if (topPaymentChart) {
            topPaymentChart.updateData(json.data[0]['top_payments'])
          }

          if (topProducts) {
            topProducts.updateData(json.data[0]['top_products'])
          }

          return json.data;
        }
      },
      columns: [
        {data: "sold_count", orderable: false},
        {data: "order_count", orderable: false},
        {data: "gross_sales", orderable: false},
        {data: "net_sales", orderable: false},
        {data: "tax_amount", orderable: false},
        {data: "total_discount_amount", orderable: false},
        {data: "refund_amount", orderable: false},
        {data: "pickup_orders", orderable: false},
        {data: "delivery_orders", orderable: false}
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      dashboardReportDataTableRetail.search($(this).val()).draw();
    })

    initializeDateRangePickers(true);
    rangePickerApplyAction(dashboardReportDataTableRetail);
  });

  $(".drawer-report-datatable").each(function(){
    var drawerReportDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      buttons : [ {
          extend : 'csvHtml5',
          exportOptions : {
            modifier : {
                order : 'applied',
                page : 'all',
                search : 'applied'
            }
          }
        } 
      ],
      columns: [
        {
          data: null,
          orderable: false,
          className: "center",
          render: function ( data, type, row ) {
            return `<a href="/drawers/${data.id}" >${data.started_at}</a>`
          }
        },
        {data: "ended_at", orderable: false},
        {data: "expected_cash", orderable: false},
        {data: "actual_amount", orderable: false},
        {data: "employee_name", orderable: false},
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      drawerReportDataTable.search($(this).val()).draw();
    })

    initializeDateRangePickers();
    rangePickerApplyAction(drawerReportDataTable);
  });

  $(".physical-inventory-item-datatable").each(function(){
    var physicalInventoryItemDatatable = $(this).DataTable({
      processing: true,
      serverSide: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      columns: [
        {
          data: null,
          orderable: false,
          className: "center",
          render: function ( data, type, row ) {
            return `<h5 class="font-weight-bold"> ${data.name} </h5>`
          }
        },
        {
          data: "item_count", orderable: false,
          className: "text-right"
        },
        {
          data: "count", orderable: false,
          className: "text-right"
        },
        {
          data: "difference", orderable: false,
          className: "text-right"
        }
      ],
      "dom": "lrtip"
    });
  });

  $(".physical-inventory-item-edit-datatable").each(function(){
    var physicalInventoryItemDatatable = $(this).DataTable({
      processing: true,
      serverSide: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      ajax:  {
        url: $(this).data('url')
      },
      createdRow: (row, data, index) => {
        $(row).attr('id', `inventory-item-${data.item_id}`);
      },
      columns: [
        {
          data: null,
          orderable: false,
          className: "center",
          render: function ( data, type, row ) {
            return `<h5 class="font-weight-bold"> ${data.name} </h5>`
          }
        },
        {
          data: null,
          orderable: false,
          className: "text-right",
          render: function ( data, type, row ) {
            return `<span class="text-right mr-4"> ${data.count || 0.0 } </span><a href="javascript:void(0)" onclick="PhysicalInventory.removeInventoryItem( '${data.id}', '${data.item_id}' )"><i class="fa fa-trash" aria-hidden="true" ></i></a>`
          }
        }
      ],
      "dom": "lrtip"
    });
  });

  $(".physical-inventory-item-edit-count-datatable").each(function(){
    var physicalInventoryItemDatatable = $(this).DataTable({
      processing: true,
      serverSide: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      ajax:  {
        url: $(this).data('url')
      },
      createdRow: (row, data, index) => {
        $(row).attr('id', `inventory-item-${data.item_id}`);
      },
      columns: [
        {
          data: null,
          orderable: false,
          className: "center",
          render: function ( data, type, row ) {
            return `<h5 class="font-weight-bold"> ${data.name} </h5>`
          }
        },
        {
          data: "item_count",
          orderable: false,
          className: "text-right"
        },
        {
          data: null,
          orderable: false,
          className: "text-right",
          render: function ( data, type, row ) {
            return `<div class="form-group"><input type="number" name="physical_inventory_edit_count_form[physical_inventory_counts][${data.id}]" id="physical_inventory_edit_count_form_physical_inventory_counts_${data.id}" value="${data.count}" step="any" class="has_value edit_count_input" onchange="Customjs.changedEditCount(this)"></div>`
          }
        }
      ],
      "dom": "lrtip"
    });
  });

  $(".tax-datatable").each(function(){

    var table =  $(this).DataTable({
      processing: true,
      serverSide: true,
      searching: false,
      ordering: false,
      stateSave: true,
      bLengthChange: false,
      pageLength: 10,
      ajax: $(this).data('url'),
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      columns: [
        {
          data: null,
          orderable: false,
          className: "center",
          render: function ( data, type, row ) {
            return `<a href="/taxes/${data.id}/edit" >${data.name}</a>`
          }
        },
        {data: "type"},
        {data: "value"}
      ]
    });
  });

  $(".merchant-location-datatable").each(function(){

    var table =  $(this).DataTable({
      processing: true,
      serverSide: true,
      searching: false,
      ordering: false,
      stateSave: true,
      bLengthChange: false,
      pageLength: 10,
      ajax: $(this).data('url'),
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      columns: [
        {
          data: null,
          orderable: false,
          className: "center",
          render: function ( data, type, row ) {
            return `<a href="/locations/${data.id}/edit" >${data.name}</a>`
          }
        },
        {data: "phone"},
        {data: "address"}
      ]
    });
  });

  $(".supplier-datatable").each(function(){
    var categoryDataTable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      "sDom": '<"top"f>rt<"bottom"pi><"clear">',
      bLengthChange: false,
      pageLength: 10,
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      ajax: $(this).data('url'),
      columns: [
        {
          data: null,
          orderable: false,
          className: "center",
          render: function ( data, type, row ) {
            return `<a href="/suppliers/${data.id}/edit" >${data.name}</a>`
          }
        },
        {data: "phone", orderable: false},
      ],
      "dom": "lrtip"
    });

    $('#tableSearchBox').keyup(function() {
      categoryDataTable.search($(this).val()).draw();
    })
  });

    $(".user-datatable").each(function(){
    var userDatatable = $(this).DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      bLengthChange: false,
      pageLength: 10,
      paging: true, // Table pagination
      ordering: true, // Column ordering
      info: true, // Bottom left status text
      rowReorder: {
          selector: 'td:nth-child(2)'
      },
      responsive: true,
      language: locale === 'en' ? englishOption : arabicOption,
      oLanguage: {
          sSearch: '<em class="fas fa-search mr-3"></em>',
          sLengthMenu: '_MENU_ records per page',
          zeroRecords: 'Nothing found - sorry',
          oPaginate: {
              sNext: '<em class="fa fa-caret-right"></em>',
              sPrevious: '<em class="fa fa-caret-left"></em>'
          }
      },
      stateSaveParams: function (settings, data) {
        data.location_filter = $('#location-id').val();
        data.brand_filter = $('#brand-id').val();
      },
      stateLoadParams: function (settings, data) {
        $('#location-id').val(data.location_filter);
        $('#brand-id').val(data.brand_filter);
      },
      ajax:  {
        url: $(this).data('url'),
        data: function(d) {
          d.location_filter = $('#location-id').val();
          d.brand_filter = $('#brand-id').val();
        },
      },
      columns: [
        {
          data: null,
          render: function ( data, type, row ) {
            return `<a href="/employees/${data.id}/edit" > ${data.full_name}</a>`
          }
        },
        {data: "phone_number"},
        // {data: "can_login", orderable: false},
        {data: "role", orderable: false},
      ],
      "dom": "lrtip"
    });
    $('#tableSearchBox').keyup(function() {
      userDatatable.search($(this).val()).draw();
    })
  });

  $( ".select2multiple" ).select2({
    theme: "classic"
  });

  const dropzone = $('#items-dropzone');
  if (dropzone.length) {
    initDropzone();
  }

  setTimeout(function(){
    $('.alert').fadeOut();
  }, 3000);

  $('#device_location_attributes_merchant_location_id').on('change', function() {
    var value = this.value;
    if (!value.length) {
      $('#device_location_attributes__destroy').val(true)
    } else {
      $('#device_location_attributes__destroy').val(false)
    }
  });

});

document.addEventListener("turbolinks:before-cache", function() {
  var dataTables = $("[class*=-datatable]");
  if (dataTables !== null) {
    dataTables.each(function(table) {
      $(this).DataTable().state.clear();
      $(this).DataTable().destroy();
    });
  }
})

document.addEventListener("turbolinks:after-cache", function() {
  const dropzone = $('#items-dropzone');
  if (dropzone.length) {
    initDropzone();
  }
})
