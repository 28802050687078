import $ from 'jquery';
import Dropzone from "dropzone/dist/dropzone-amd-module"
Dropzone.autoDiscover = false;

function initDropzone() {
  var locale = I18n.locale
  // Now that the DOM is fully loaded, create the dropzone, and setup the
  // event listeners
  var options = {
    acceptedFiles:".csv",
    success: (file, response) => {
      if (response.message !== 'success' && response.status == 'import_now') {
        $('#items-dropzone .dz-message').html( `<h2 class="w-100 p-5 text-center" id="dropzone-custom-msg">${response.message}</h2>`)
      } else if (response.status == 'import_later') {
        alert(response.message)
        location.reload();
      }
      else {
        location.reload();
      }
    }
  }

  var processingMessage = `
    <h2 class="w-100 p-5 text-center" id="dropzone-custom-msg">
      ${I18n['Processing File']}
    </h2>`

  var itemsDropzone = new Dropzone(
    "#items-dropzone", options
  );

  itemsDropzone.on("uploadprogress", function(file) {
    $('#items-dropzone .dz-message').html(processingMessage)
  });

  itemsDropzone.on("canceled", function(file) {
    alert('upload canceled')
    $('#dropzone-custom-msg').remove()
    $("#image-dropzone-modal").css('z-index', 1050)
  });

  itemsDropzone.on("error", function(file) {
    alert('something went wrong')
    $('#dropzone-custom-msg').remove()
    $("#image-dropzone-modal").css('z-index', 1050)
  });

  itemsDropzone.on("complete", function(file) {
    // location.reload();
  });
}

export default initDropzone;