import $ from 'jquery';
import introJs from 'intro.js/minified/intro.min.js';

window.Customjs = {}

Customjs.setOnBoarding = (data) => {
  $.ajax({
    type: "POST",
    url: "/employees/set_onboarding",
    data: data,
    success: (response) => {
    },
    error: (response) => {
    }
  })
}

Customjs.onCloseOnboardingModal = (updateOnboarding) => {
  $('#afterSignUp').modal('hide');
  Customjs.setOnBoarding()
  if (updateOnboarding) {
    Customjs.showOnboardingTips()
  }
}

Customjs.showOnboardingTips = () => {
  var ele = document.getElementsByClassName("show_onboarding_tips")
  if (ele) {
    const IntroJs = introJs();
    // IntroJs.onbeforechange(function(element) {
    //   if (this._currentStep === 2) {
    //     setTimeout(function() {
    //       $("#settings").addClass("show");
    //     });
    //   }
    // });
    IntroJs.setOptions({
      disableInteraction: false,
      exitOnOverlayClick: false,
      highlightClass: 'active',
      showBullets: false,
      exitOnEsc: false,
      // buttonClass: "btn btn-block"
      nextLabel: I18n["next"],
      prevLabel: I18n["previous"],
      doneLabel: I18n["done"]
    }).start()
  }
}

function removeParam(key, sourceURL) {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

function handleLocationFilter() {
  const exportCSV = $("#ExportReporttoCSV");
  var href = $("#ExportReporttoCSV").attr("href");
  var location_filter = $("#location-id").val()
  var filter_param = "";

  if (location_filter != "") {
    if (href && href.split("?").length > 1) {
      filter_param = `&location_filter=${location_filter}`;
    } else {
      filter_param = `?location_filter=${location_filter}`;
    }
    if (exportCSV.length) {
      $("#ExportReporttoCSV").attr("href", href + filter_param);
    }
  } else {
    if (exportCSV.length) {
      var rtn = removeParam("location_filter", href);
      $("#ExportReporttoCSV").attr("href", rtn);
    }
  }
}

Customjs.filter = () => {
  $('.table').DataTable().ajax.reload();
}

function handleFilter() {
  const exportCSV = $("#ExportReporttoCSV");
  var href = $("#ExportReporttoCSV").attr("href");

  var brand_filter = $("#brand-id").val();
  var location_filter = $("#location-id").val();
  var filter_param = "";
  if (brand_filter !== undefined && brand_filter !== "") {

    if (href && href.split("?").length > 1) {
      filter_param += `&brand_filter=${brand_filter}`;
    } else {
      filter_param += `?brand_filter=${brand_filter}`;
    }
    if (exportCSV.length) {
      $("#ExportReporttoCSV").attr("href", href + filter_param);
    }
  } else {
    if (exportCSV.length) {
      var rtn = removeParam("brand_filter", href);
      $("#ExportReporttoCSV").attr("href", rtn);
    }
  }

  if (location_filter != "") {
    if (href && href.split("?").length > 1) {
      filter_param += `&location_filter=${location_filter}`;
    } else {
      filter_param += `?location_filter=${location_filter}`;
    }
    if (exportCSV.length) {
      $("#ExportReporttoCSV").attr("href", href + filter_param);
    }
  } else {
    if (exportCSV.length) {
      var rtn = removeParam("location_filter", href);
      $("#ExportReporttoCSV").attr("href", rtn);
    }
  }
  console.log('filter: ', filter_param)
}

Customjs.reloadDataTable = (function () {
  // $('.table').DataTable().ajax.reload();
  handleFilter()
});

Customjs.addOption = (function () {
  var index = 0;
  var ele = document.getElementById("options-div")
  var lastChild = ele.lastElementChild
  if (lastChild != null) {
    index = parseInt(lastChild.id.split("_")[1]) + 1
  }

  var option_temp = `
  <div class="form-row align-items-center mt-2" id="option_${index}">
    <div class="col-md-5">
      <label>
        ${I18n.modifier.option_name}
      </label>
      <input type="text" name="modifier[options][][option_name]" id="modifier_options_option_name" value="" class="form-control" required>
    </div>

    <div class="col-md-5">
      <label>
        ${I18n.modifier.price}
      </label>  
      <input type="text" name="modifier[options][][option_price]" id="modifier_options__option_price" value="" class="form-control maskmoney" required>
    </div>

    <div class="col-md-2 mt-1 align-items-center">
      <input type="button" class="btn mt-4 btn-primary" value='${I18n.modifier.remove_option}' onclick="Customjs.removeOption('option_${index}')">
    </div>
  </div>`

  $("#options-div").append(option_temp)
  Customjs.initializeMaskMoney();
});

Customjs.removeOption = (function (id) {
  var ele = document.getElementById(id)
  ele.remove()
});

Customjs.changedEditCount = (function (element) {
  $(element).addClass('changed')
});

Customjs.saveEditCountForm = (function (event, type) {
  event.preventDefault()
  $('input[type=number]:not(.changed)').prop('disabled', true);
  $("input[id=physical_inventory_edit_count_form_commit]").val(type);
  $('#edit_count_form').submit();
})

Customjs.initializeMaskMoney = (function () {
  var elements = $(".maskmoney");
  elements.each(function (index, element) {
    $(element).maskMoney({ allowNegative: true, decimal: '.', affixesStay: false, allowZero:true });
  });
});

Customjs.onPriceClick = (evt) => {
  const target = evt.target.target;
  const showElms = document.querySelectorAll(`[id=${target}]`);
  const hideElms = document.querySelectorAll('.tab-pane.active');
  Subscriptionjs.removeCoupon(evt)
  hideElms.forEach((elm) => {
    elm.classList.remove('active');
    elm.classList.remove('show');
  });

  setTimeout(() => {
    showElms.forEach((elm) => {
      elm.classList.add('active');
      elm.classList.add('show');
    });
    Customjs.UpdateCost();
  }, 50)
}

// show spinner on AJAX start
$(document).ajaxStart(function () {
  // $("input").attr("disabled", "disabled");
  $(".spinner-border").show();
});

// hide spinner on AJAX stop
$(document).ajaxStop(function () {
  $(".spinner-border").hide();
  // $("input").removeAttr("disabled");
});

Customjs.selectPlan = () => {
  // $(".select_button").click((event) => {
  let target = event.target.target;
  let hideElms = document.querySelectorAll('.btn.active');
  hideElms.forEach((elm) => {
    elm.classList.remove('active', 'btn-primary');
    elm.classList.add('btn-light');
  });
  event.target.classList.remove('btn-light');
  event.target.classList.add('active', 'btn-primary');
  Customjs.UpdateCost(event);
}

$('#tabs > li > a').on("click", (event) => {
  const notAlreadySelected = event.target.ariaSelected !== 'true';
  event.preventDefault();
  if (notAlreadySelected) {
    setTimeout(function () {
      Customjs.UpdateCost();
    }, 100);
  }
});

Customjs.reCalculate = (event) => {
  event.preventDefault();
  Customjs.UpdateCost();
}

Customjs.UpdateCost = () => {
  let url = "/subscriptions/costs";
  let plan = $(".plan-button.active").attr('value');
  let plan_id = $(".plan-button.active").data('id');
  let payment_intervel = $("ul#tabs li a.active").attr('value');
  var selected_locations_number = document.getElementById('selected_locations_number');
  var selected_registers_number = document.getElementById('selected_registers_number');

  if (selected_registers_number.value < selected_locations_number.value) {
    selected_registers_number.value = selected_locations_number.value
  }
  selected_registers_number.setAttribute("min", selected_locations_number.value);
  $("#selected_plan_code").val(plan);
  $("#selected_payment_intervel").val(payment_intervel);


  $.ajax({
    url: url,
    type: "GET",
    contentType: 'application/json',
    dataType: 'json',
    async: false,
    data: {
      plan_code: plan,
      payment_intervel: payment_intervel,
      selected_locations_number: selected_locations_number.value,
      selected_registers_number: selected_registers_number.value
    },
    success: function (data) {
      let plan_cost = data['plan_cost'];

      $('#locations_monthly_total').text(Dinero({ amount: plan_cost.locations_total_cost, currency: 'SAR' }).toFormat('$0,0'));
      $('#registers_monthly_total').text(Dinero({ amount: plan_cost.reigsters_total_cost, currency: 'SAR' }).toFormat('$0,0'));
      $('#register_cost').text(Dinero({ amount: plan_cost.register_cost, currency: 'SAR' }).toFormat('$0,0'));
      $('#plan_cost').text(Dinero({ amount: plan_cost.monthly_cost, currency: 'SAR' }).toFormat('$0,0'));
      $('#subscription_net_total_amount').text(Dinero({ amount: plan_cost.net_total_amount, currency: 'SAR' }).toRoundedUnit(2).toLocaleString("en-US", {style: "currency", currency: 'SAR'}));
      $('#place_order_btn').text(Dinero({ amount: plan_cost.net_total_amount, currency: 'SAR' }).toRoundedUnit(2).toLocaleString("en-US", {style: "currency", currency: 'SAR'}));
      $('#subscription_subtotal_amount').text(Dinero({ amount: plan_cost.subtotal_amount, currency: 'SAR' }).toRoundedUnit(2).toLocaleString("en-US", {style: "currency", currency: 'SAR'}));
      $('#subscription_tax').text(Dinero({ amount: plan_cost.tax_amount, currency: 'SAR' }).toRoundedUnit(2).toLocaleString("en-US", {style: "currency", currency: 'SAR'}));
      $('#subscription_discount').text(Dinero({ amount: plan_cost.discount_amount, currency: 'SAR' }).toRoundedUnit(2).toLocaleString("en-US", {style: "currency", currency: 'SAR'}));
      $('#cost_per_extra_register').text(Dinero({ amount: plan_cost.register_cost, currency: 'SAR' }).toFormat('$0,0'));
      $("#selected_subscription_id").val(plan_cost.id);

      if (plan_cost && plan_cost.can_add_locations) {
        $("#selected_locations_number").removeAttr("disabled");
      } else {
        $("#selected_locations_number").attr("disabled", "disabled");
        $("#selected_locations_number").val("1");
      }
    },
    error: function () {
      console.log('failure')
    }
  });
}