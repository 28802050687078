
window.Subscriptionjs = {}

document.addEventListener('turbolinks:load', () => {
  $('#coupon').on('input', function(e) {
    e.preventDefault()
    const value = $("#coupon").val();
    const couponButton = document.getElementById("coupon-addon");
    $('#coupon').css('border-color', '');
    if (value.trim().length !== 0) {
      couponButton.classList.remove("d-none");
    } else {
      couponButton.classList.add("d-none");
    }
  });

  $("#coupon-addon").click(function(e) {
    e.preventDefault()
    const value = $('#coupon').val();
    const couponButton = document.getElementById("coupon-addon");
    const couponButtonLoading = document.getElementById("coupon-addon-loading");
    const couponButtonRemove = document.getElementById("coupon-addon-remove");
    couponButton.classList.add("d-none");
    couponButtonLoading.classList.remove("d-none");
    $.ajax({
      url: '/subscriptions/check_coupon',
      type: "GET",
      contentType: 'application/json',
      dataType: 'json',
      data: {coupon_code: value},
      success: function(response){
        if (response && response.status === 'valid') {
          showMessage("#coupon-message",  I18n["coupon_added"]);
          $("#coupon").prop("disabled", true);
          $('#coupon').css('border-color', 'green');
          couponButtonRemove.classList.remove("d-none");
          Customjs.reCalculate(e)
        } else {
          $('#coupon').css('border-color', 'red');
          showMessage("#coupon-message", I18n["coupon_not_valid"]);
        }
        couponButton.classList.add("d-none");
        couponButtonLoading.classList.add("d-none");
      }
    })
  });

  $("#coupon-addon-remove").click(function(e) {
    e.preventDefault()
    Subscriptionjs.removeCouponAndRecalculate(e);
  });
})

Subscriptionjs.removeCouponAndRecalculate = (e) => {
  e.preventDefault();
  const couponButton = document.getElementById("coupon-addon");
  const couponButtonRemove = document.getElementById("coupon-addon-remove");
  $.ajax({
    url: '/subscriptions/remove_coupon',
    type: "GET",
    contentType: 'application/json',
    dataType: 'json',
    data: {},
    success: function(response){
      if (response && response.status === 'removed') {
        $("#coupon").prop("disabled", false);
        $("#coupon").val("");
        $('#coupon').css('border-color', '');
        couponButtonRemove.classList.add("d-none");
        Customjs.reCalculate(e);
      } else {
        showMessage("#coupon-message", I18n["try_again"]);
      }
    }
  })
}


Subscriptionjs.removeCoupon = (e) => {
  e.preventDefault();
  const couponButton = document.getElementById("coupon-addon");
  const couponButtonRemove = document.getElementById("coupon-addon-remove");
  $.ajax({
    url: '/subscriptions/remove_coupon',
    type: "GET",
    contentType: 'application/json',
    dataType: 'json',
    async: false,
    data: {},
    success: function(response){
      if (response && response.status === 'removed') {
        $("#coupon").prop("disabled", false);
        $("#coupon").val("");
        $('#coupon').css('border-color', '');
        couponButtonRemove.classList.add("d-none");
      } else {
        showMessage("#coupon-message", "Try again");
      }
    }
  })
}

function showMessage(selector, messageText) {
  const messageContainer = document.querySelector(selector);

  messageContainer.classList.remove("hidden");
  messageContainer.textContent = messageText;

  setTimeout(function () {
    messageContainer.classList.add("hidden");
    messageContainer.textContent = "";
  }, 5000);
}

// Show a spinner on payment submission
function setLoading(isLoading) {
  if (isLoading) {
    // Disable the button and show a spinner
    document.querySelector("[data-target=submit]").disabled = true;
    document.querySelector("#spinner").classList.remove("hidden");
    document.querySelector("#button-text").classList.add("hidden");
  } else {
    document.querySelector("[data-target=submit]").disabled = false;
    document.querySelector("#spinner").classList.add("hidden");
    document.querySelector("#button-text").classList.remove("hidden");
  }
}